//** Import Modules */
import { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";

//** Import Assets */
import "../../assets/css/Directory.css";

//** Import Components */
import { useLocation } from "react-router-dom";
import PageTitle from "../../components/header/PageTitle";
import axiosGeneral from "../../utils/axiosGeneral";
import DirectoryListing from "./DirectoryListing";
import RestaurantListing from "./RestaurantListing";
import DirectorySidebar from "./DirectorySidebar";
import Advertisements from "../../components/ads/Advertisements";

import { Tabs } from "antd";

export default function Directory() {
  const [cities, setCities] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchData, setSearchData] = useState({});
  const search = useLocation().search;
  const childCompRef = useRef(null);

  useEffect(() => {
    getAllCities();
    setSearchData(search);
  }, []);

  const getAllCities = async () => {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/city/getAllCities`
    );
    if (response && response.status == 200) {
      if (response.data && response.data.length) {
        setCities(
          response.data.map((city) => {
            return {
              ...city,
              value: city.name,
            };
          })
        );
      }
    }
  };

  const onTagList = (tagList) => {
    tagList = tagList.filter(
      (item, index, inputArray) =>
        index === inputArray.findIndex((t) => t.id === item.id)
    );
    setTags(tagList);
  };

  const onChange = (data) => {
    setSearchData(data);
  };

  const URLQueryVars = new URL(document.location).searchParams;
  const location = URLQueryVars.get("location");

  return (
    <div id="bizzll-page">
      <Helmet>
        <title>Bizzll Directory</title>
        <meta name="description" content="This is the directory of Bizzll" />
      </Helmet>

      <PageTitle
        pageTitle={<>{location ? `Directory - ${location}` : "Directory"}</>}
        displaySearch={true}
        ref={childCompRef}
        onChange={onChange}
        searchData={searchData}
        cities={cities}
      />

      <div id="bizzll-directory" className="page-with-sidebar">
        <div className="inner-container">
          <aside className="page-sidebar">
            <DirectorySidebar
              cities={cities}
              tags={tags}
              setSearchData={setSearchData}
              searchData={searchData}
            />
          </aside>

          <div className="page-content">
            <Advertisements type="carousel" />
            <Tabs defaultActiveKey="1" type="card" size="default">
              <Tabs.TabPane tab="Virtual Offices" key="1">
                <DirectoryListing onTagList={onTagList} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Restaurants" key="2">
                <RestaurantListing onTagList={onTagList} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
